import { FC, ReactElement, useCallback, useState } from 'react'

import cn from 'classnames'

import ButtonOrange from '../button/_color/_orange'

import { defaultQuestions, IQuestion } from './questions'

import styles from './styles.module.scss'
import IconQuestions from './questions.png'
import IconPlus from './icon-plus.svg'
import { useTranslation } from 'utils/use-translation'

const MAX_QUESTIONS_AMOUNT = 5

export const Questions: FC<{
  coinQuestions?: IQuestion[]
  title?: string
  image?: string
}> = ({ coinQuestions = null, title = null, image = null }) => {
  const { t } = useTranslation()

  const [activeTab, setActiveTab] = useState(0)
  const [closedTab, setClosedTab] = useState(0)
  const [isListExpanded, setIsListExpanded] = useState(false)

  const toggleTab = useCallback(
    (id) => {
      return function () {
        setClosedTab(activeTab !== id ? activeTab : id)
        setActiveTab(activeTab !== id ? id : 0)
      }
    },
    [activeTab, closedTab],
  )

  function getStylesForTab(id) {
    return {
      [styles.openedTab]: activeTab === id,
      [styles.closedTab]: closedTab === id,
    }
  }

  const questions = coinQuestions || defaultQuestions
  const lengthLimit = isListExpanded ? questions.length : Math.min(questions.length, MAX_QUESTIONS_AMOUNT)

  const renderList: ReactElement[] = []
  for (let index = 0; index < lengthLimit; index++) {
    const question = questions[index]

    renderList.push(
      <div key={index} className={cn(styles.tab, getStylesForTab(index + 1))}>
        <div className={styles.tabHead} onClick={toggleTab(index + 1)}>
          <span>{t(question.title)}</span>
          <img src={IconPlus} alt="IconPlus" width={20} height={20} loading={'lazy'} />
        </div>
        <div className={styles.tabContent} dangerouslySetInnerHTML={{ __html: t(question.answer) }}></div>
      </div>,
    )
  }

  return (
    <div className={cn(styles.block)} id="how-it-works">
      <div className={cn('container', styles.blockWrapper)}>
        <div className={styles.content}>
          <h2 className={styles.title}>{title || t('what_you_should_know_about_loans')}</h2>
          <div className={styles.questionsList}>
            {renderList}
            {!isListExpanded && MAX_QUESTIONS_AMOUNT < questions.length && (
              <div className={styles.showMore}>
                <div className={styles.showMoreBtnWrapper}>
                  <ButtonOrange className={styles.showMoreBtn} onClick={() => setIsListExpanded(true)}>
                    {t('show_more')}
                  </ButtonOrange>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.image}>
          <img
            src={image || IconQuestions}
            alt="how to borrow cryptocurrency"
            width={437}
            height={404}
            loading="lazy"
          />
        </div>
      </div>
    </div>
  )
}
