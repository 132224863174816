import { FC, ReactElement, useEffect, useRef, useState } from 'react'
import cn from 'classnames'

import styles from './styles.module.scss'

export interface ISlideParams {
  id: string | number
  link: string
  author: {
    avatar?: string
    avatarAlt?: string
    name: string
    position?: string
  }
  image: any
  isWidget?: boolean
  imageAlt: string
  imageWidth: number
  title: string | ReactElement
  text: string | ReactElement
}

export const Slide: FC<ISlideParams> = (props) => {
  const [isClient, setIsClient] = useState(false)

  const ref = useRef<HTMLDivElement>(null)
  const mobRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (props.isWidget && ref.current && isClient) {
      setTimeout(() => {
        if (window.Trustpilot && ref.current) {
          window.Trustpilot.loadFromElement(ref.current.getElementsByClassName('trustpilot-widget')[0])
        }

        if (window.Trustpilot && mobRef.current) {
          window.Trustpilot.loadFromElement(mobRef.current.getElementsByClassName('trustpilot-widget')[0])
        }
      }, 500)
    }
  }, [props.isWidget, ref.current, mobRef.current, isClient])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsClient(true)
    }
  }, [])

  return (
    <div className={styles.slide} suppressHydrationWarning>
      <a href={props.link} target="_blank" rel="noopener nofollow noreferrer">
        <div className={styles.answer}>
          {props.author.avatar && (
            <img
              src={props.author.avatar}
              className={styles.avatar}
              alt={props.author.avatarAlt}
              loading="lazy"
              width={60}
              height={60}
            />
          )}
          <div className={styles.author}>
            <div className={styles.name}>{props.author.name}</div>
            {props.author.position && <div className={styles.position}>{props.author.position}</div>}
          </div>

          {isClient &&
            (props.isWidget ? (
              <div className={cn(styles.firm, styles.desktop)} ref={ref}>
                {props.image}
              </div>
            ) : (
              <img
                src={props.image}
                className={cn(styles.firm, styles.desktop)}
                alt={props.imageAlt}
                height={56}
                width={props.imageWidth}
                loading="lazy"
              />
            ))}
        </div>
        <header className={styles.header}>{props.title}</header>
        <div className={styles.body}>{props.text}</div>
        {isClient &&
          (props.isWidget ? (
            <div className={cn(styles.firm, styles.mobile)} ref={mobRef}>
              {props.image}
            </div>
          ) : (
            <img
              src={props.image}
              className={cn(styles.firm, styles.mobile)}
              alt={props.imageAlt}
              height={56}
              width={props.imageWidth}
              loading="lazy"
            />
          ))}
      </a>
    </div>
  )
}
