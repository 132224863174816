export interface IQuestion {
  title: string
  answer: string
}

export const defaultQuestions: IQuestion[] = [
  {
    title: 'how_crypto_loan_works',
    answer: 'how_crypto_loan_works_answer',
  },
  {
    title: 'what_is_apr',
    answer: 'what_is_apr_answer',
  },
  {
    title: 'how_quickly_refund',
    answer: 'how_quickly_refund_answer',
  },
  {
    title: 'what_if_rate_changes',
    answer: 'what_if_rate_changes_answer',
  },
  {
    title: 'what_is_margin_call',
    answer: 'what_is_margin_call_answer',
  },
  {
    title: 'what_is_ltv',
    answer: 'what_is_ltv_answer',
  },
  {
    title: 'can_close_loan',
    answer: 'can_close_loan_answer',
  },
  {
    title: 'what_if_not_repay',
    answer: 'what_if_not_repay_answer',
  },
  {
    title: 'can_have_multiple_loans',
    answer: 'can_have_multiple_loans_answer',
  },
  {
    title: 'how_save_coinrabbit',
    answer: 'how_save_coinrabbit_answer',
  },
]